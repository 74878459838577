import styled from "@emotion/styled"
import { H6 } from "@styles/Global.styles"
import { GridRow, Search, Form, Loader } from "semantic-ui-react"
import React from "react"
import { KpFormDropdown } from "@styles/Global.forms"
import { Field } from "formik"
import theme from "@styles/theme"
import { isMobile } from "@utils/Helpers"

export const PersonalDetailsManualAddressContainer = styled.div`
  border-top: 2px solid black;
  border-bottom: 2px solid black;
`

export const PersonalDetailsManualAddressContainerHeading = styled(H6)`
  color: ${theme.brand.colors.white} !important;
  margin-bottom: 30px !important;
  display: inline-block;
`
export const AddressFinderResultItem = styled.div`
  width: 100% !important;
`
export const AddressSearchTitleSection = styled.div`
  clear: both;
  & > label {
    text-align: left;
    font-size: 13px !important;
    line-height: 19px;
    float: left;
    font-weight: bold;
  }
`
export const AddressToggerLink = styled.span`
  cursor: pointer;
  text-align: right;
  float: right;
  color: #707070;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 19px;
  padding-left: 5px;
  &:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
  `
export const AddressSearchAutoComplete = styled(({ ...others }) => (
  <Search {...others} />
))`
  clear: both;

  & > div > input {
    height: 50px;
    font-size: 16px !important;
    line-height: 16px !important;
    width: 100%;
  }
`
export const AddressFinderFormGroup = styled(({ show, ...others }) => (
  <Form.Group {...others} />
))`
  display: ${props => (props.show ? "block" : "none !important")};
`
export const PersonalDetailsContainerLink = styled.span`
  cursor: pointer;
  color: ${theme.brand.colors.green};
  font-weight: bold;
  font-size: 13px !important;
  line-height: 19px;

  &:hover {
    text-decoration: underline;
    color: ${theme.brand.colors.green};
  }
`
export const ExpressCheckoutToggleContainer = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`
export const PersonalDetailsFormContainer = styled.div`
  margin-bottom: 40px;
  ${isMobile() && `color: ${theme.mobileTheme.color}`}
`

export const PersonalDetailsButtonContainer = styled(GridRow)`
  margin-top: 10px;

  #ownership-type {
    text-transform: unset !important;
    text-align: left;
    padding: 20px 20px 15px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  h5 {
    color: #000 !important;
    text-align: left !important;
  }
`
export const PersonalDetailsFormGroup = styled.div`
  margin: 15px 0 30px 0;
`
export const FormikContainer = styled(({ show, ...others }) => (
  <div {...others} />
))`
  display: ${props => (props.show ? "block" : "none")};
`

export const PersonalDetailsDOBContainer = styled.div`
  display: flex;
  width: 100%;
`

export const PersonalDetailsTitleDropdown = styled(({ show, ...others }) => (
  <KpFormDropdown {...others} />
))`
  > i {
    margin: 0 !important;
  }

  ${isMobile() && `
    background-color: ${theme.mobileTheme.bgColor} !important;
    color: ${theme.mobileTheme.color} !important;
    border-bottom: 2px solid ${theme.mobileTheme.color} !important;
    border-radius: unset !important;
    border: 0px !important;
  ` }
`

export const FormikFieldEmailValidationInput = styled(
  ({ inputValid, ...others }) => <Field {...others} />
)`
  border-left: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "4px solid green !important"
      : "4px solid red !important"};
  border-top: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
  border-right: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
  border-bottom: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
`
export const InlineEmailValidatingLoader = styled(Loader)`
  float: right;
  margin-top: -36px !important;
  margin-right: 10px !important;
`
export const SignupEmailValidatingLoader = styled(Loader)`
  float: right;
  margin-top: -36px !important;
  margin-right: 10px !important;
  background-color: #33a3dc;
  border-radius: 12px;
`
export const InputCapStyle = styled.input`
  text-transform: uppercase;
  ::-webkit-input-placeholder { /* WebKit browsers */
      text-transform: none;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      text-transform: none;
      color: ${theme.brand.colors.lightGrey}  !important;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
      text-transform: none;
      color: ${theme.brand.colors.lightGrey}  !important;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
      text-transform: none;
      color: ${theme.brand.colors.lightGrey}  !important;
  }
  ::placeholder { /* Recent browsers */
      text-transform: none;
      color: ${theme.brand.colors.lightGrey}  !important;
  }
`

