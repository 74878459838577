
import { PersonalDetailsFormContentModelKentico } from "@models/PersonalDetailsForm/PersonalDetailsFormContentModelKentico";
import { PersonalDetailsFormContentModel } from "@models/PersonalDetailsForm/PersonalDetailsFormContentModel";

export const mapToPersonalDetailsForm = (data: PersonalDetailsFormContentModelKentico): PersonalDetailsFormContentModel => {
    return {
        applicantDetailsMessage: {
            body: data.applicant_details_message.linked_items[0].elements.body.value,
            heading: data.applicant_details_message.linked_items[0].elements.heading.value
        },
        ownerDetailsMessage: {
            body: data.owner_details_message.linked_items[0].elements.body.value,
            heading: data.owner_details_message.linked_items[0].elements.heading.value
        },
        multiPlateOrderMessage: {
            body: data.multi_plate_order_message.linked_items[0].elements.body.value,
            heading: data.multi_plate_order_message.linked_items[0].elements.heading.value
        },
        vehicleDetailsMessage: {
            body: data.vehicle_details_message.linked_items[0].elements.body.value,
            heading: data.vehicle_details_message.linked_items[0].elements.heading.value
        },
        ownerDetailsMessageGifting: {
            body: data.owner_details_message_gifting.linked_items[0].elements.body.value,
            heading: data.owner_details_message_gifting.linked_items[0].elements.heading.value
        },
    }
}