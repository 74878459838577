import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { mapToSEO, SEO } from '@components/SEO'
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { Grid, } from "semantic-ui-react"
import PersonalDetailsForm from "@components/PersonalDetailsForm/PersonalDetailsForm"
import OrderSummary from "@components/OrderSummary/OrderSummary"
import theme from "@styles/theme"
import { mapToPersonalDetailsForm } from "@mappers/PersonalDetailsForm/mapper"
import { currentCheckoutSelector } from '@redux/selectors/checkoutSelector';
import { updateManufactureNow, updatePersonalDetails, updateOwnerAddress, updateDeliveryAddress, } from '@redux/actions/checkout'
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { Address, Checkout } from "@models/Checkout/Checkout"
import { currentBasketSelector } from "@redux/selectors/basketSelector"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import moment from "moment"
import OwnerDetailsForm from "@components/Dealer/OwnerDetailsForm"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { isMobile } from "@utils/Helpers";
import MobilePersonalDetailsForm from "@components/Mobile/PersonalDetailsForm/PersonalDetailsForm"
import { constant } from "lodash"
import { PersonalDetailsSection } from "@utils/Constant"
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader"
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer"
import { getCurrentBasket } from "@redux/localStorage/basket"

interface Props {
  data: any
  location: any
  checkoutState: Checkout
  basket: BasketResponseModel
  updateManufactureNow: (manufactureNow: boolean) => void
  updatePersonalDetails: (personalDetails: Checkout) => void
  updateOwnerAddress: (ownerAddress: Address) => void
  updateDeliveryAddress: (deliveryAddress: Address) => void
  updateDealerAddress: (dealerAddress: Address) => void
  updateIsDealerOrder: (isDealerOrder: boolean) => void
  updatePaymentType: (paymentType: number) => void
}

class PersonalDetailsFormPageContainer extends React.Component<Props>{

  renderMobileComponent()  {
    const { elements } = this.props.data.kontentItemPersonalDetailsFormPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const emailValidationToken = this.props.data.site.siteMetadata.emailValidationToken;
    const addressValidationToken = this.props.data.site.siteMetadata.addressValidationToken;
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;
    const personalDetailsFormContentData = mapToPersonalDetailsForm(elements);

    var hash = typeof window !== `undefined` ?  window.location.hash.substring(1) : PersonalDetailsSection.OwnershipType;

    return (
      <MobilePersonalDetailsForm
              apiUrl={apiUrl}
              emailValidationToken={emailValidationToken}
              addressValidationToken={addressValidationToken}
              reCaptchaSiteKey={reCaptchaSiteKey}
              reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
              content={personalDetailsFormContentData}
              checkoutState={this.props.checkoutState}
              basketState={this.props.basket}
              updateDeliveryAddress={this.props.updateDeliveryAddress}
              updateManufactureNow={this.props.updateManufactureNow}
              updatePersonalDetails={this.props.updatePersonalDetails}
              updateOwnerAddress={this.props.updateOwnerAddress}
              section={hash ? hash : PersonalDetailsSection.OwnershipType}
            />
    );
  }

  render() {
    const { elements } = this.props.data.kontentItemPersonalDetailsFormPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const emailValidationToken = this.props.data.site.siteMetadata.emailValidationToken;
    const addressValidationToken = this.props.data.site.siteMetadata.addressValidationToken;
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;
    const seoData = mapToSEO(elements);
    const personalDetailsFormContentData = mapToPersonalDetailsForm(elements);
    const checkout = getCurrentCheckout()
    checkout.vehicleDetails.dob = moment(checkout.vehicleDetails.dob?.toString()).toDate();
    const basket = getCurrentBasket()

    if(isMobile() === undefined){
      return null;
    }

    return (<Layout version="simple" currentStep={3} location={this.props.location}>
      <SEO {...seoData} />
      <MobileGenericBannerContainer
        padding={{
          mobile: {
            top: 20,
            bottom: 40
          },
          desktop: {
            top: 40,
            bottom: 60
          }
        }}
        backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
        <Grid stackable reversed='mobile tablet vertically'>
          <Grid.Column mobile={16} tablet={16} computer={10}>
            {checkout.isDealerOrder ?
              <OwnerDetailsForm
                apiUrl={apiUrl}
                updateOwnerDetails={this.props.updatePersonalDetails}
                checkout={checkout}
                basket={basket}
                content={personalDetailsFormContentData}
                emailValidationToken={emailValidationToken}
                reCaptchaSiteKey={reCaptchaSiteKey}
                reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
                updateOwnerAddress={this.props.updateOwnerAddress}
                addressValidationToken={addressValidationToken}
              /> :
              <React.Fragment>
                {isMobile() ?
                  this.renderMobileComponent()
                :
                <PersonalDetailsForm
                  apiUrl={apiUrl}
                  emailValidationToken={emailValidationToken}
                  addressValidationToken={addressValidationToken}
                  reCaptchaSiteKey={reCaptchaSiteKey}
                  reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}
                  content={personalDetailsFormContentData}
                  checkoutState={this.props.checkoutState}
                  basketState={this.props.basket}
                  updateDeliveryAddress={this.props.updateDeliveryAddress}
                  updateManufactureNow={this.props.updateManufactureNow}
                  updatePersonalDetails={this.props.updatePersonalDetails}
                  updateOwnerAddress={this.props.updateOwnerAddress}
                />}
              </React.Fragment>
              }
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
            {isMobile() ?
            <NavHeader link={checkout.ownerIsApplicant ? "/plate-manufacture-selection" : (checkout.isGifting ? "/gifting-options-selection" : "/plate-owner-selection")} info="Personal Details"/>:
            <OrderSummary
              isLayby={false}
              basket={this.props.basket}
              apiUrl={apiUrl}
              showSurcharge={false}></OrderSummary>
            }
          </Grid.Column>
        </Grid>
      </MobileGenericBannerContainer>
    </Layout>
    );
  }
}



const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS() as Checkout;
  checkout.vehicleDetails.dob = moment(checkout.vehicleDetails.dob?.toString()).toDate();
  const basket = currentBasketSelector(state).toJS();
  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateManufactureNow(manufactureNow: boolean) {
    dispatch(updateManufactureNow(manufactureNow));
  },
  updatePersonalDetails(personalDetails: Checkout) {
    let validatedPersonalDetails = personalDetails;
    validatedPersonalDetails.applicantDetails.email = validatedPersonalDetails.applicantDetails.email.trim();
    validatedPersonalDetails.ownerDetails.email = validatedPersonalDetails.ownerDetails.email.trim();
    validatedPersonalDetails.vehicleDetails.driverLicense = validatedPersonalDetails.vehicleDetails.driverLicense.toUpperCase();
    validatedPersonalDetails.vehicleDetails.existingCombination = validatedPersonalDetails.vehicleDetails.existingCombination.toUpperCase();
    validatedPersonalDetails.vehicleDetails.vehicleModel = validatedPersonalDetails.vehicleDetails.vehicleModel.toUpperCase();
    validatedPersonalDetails.vehicleDetails.vehicleMake = validatedPersonalDetails.vehicleDetails.vehicleMake.toUpperCase();
    dispatch(updatePersonalDetails(validatedPersonalDetails))
  },
  updateOwnerAddress(ownerAddress: Address) {
    dispatch(updateOwnerAddress(ownerAddress))
  },
  updateDeliveryAddress(deliveryAddress: Address) {
    dispatch(updateDeliveryAddress(deliveryAddress));
  },
})

const PersonalDetailsFormPage = connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsFormPageContainer)
export default PersonalDetailsFormPage

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
      addressValidationToken
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  kontentItemPersonalDetailsFormPage {
    elements {
      applicant_details_message {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            id
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      multi_plate_order_message {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            id
            elements {
              heading {
                value
              }
              body {
                value
              }
            }
          }
        }
      }
      owner_details_message {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            id
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      owner_details_message_gifting{
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            id
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      page_info___checkout__progress {
        value {
          codename
          name
        }
      }
      page_info___checkout__title {
        value
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          name
          codename
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      url{
        value
      }
      vehicle_details_message {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            id
            elements {
              heading {
                value
              }
              body {
                value
              }
            }
          }
        }
      }
    }
  }
}

`