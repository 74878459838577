
import {DEALER_CHECKOUT} from "gatsby-env-variables";

export const DEALER_CHECKOUT_AWS = {
        AWSS3: {
          region: DEALER_CHECKOUT.AWSS3.region,
          bucket: DEALER_CHECKOUT.AWSS3.bucket,
        },
      
}
