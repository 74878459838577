import Axios from 'axios'
import { AwsStsUrls } from '@utils/AwsStsUrls';
import { AwsStsCredentials } from './Types';

import { DEALER_CHECKOUT_AWS } from "@utils/Configuration"
const AWS = require('aws-sdk')


const getStsToken = async (apiUrl:string, userKey: string) =>{
    const url = AwsStsUrls.getAwsStsTokenUrl(apiUrl, userKey);
    const result = await Axios.get(url)
    const data = result.data;
    if(data.Success){
        return data.Data;
    }
}

const deleteFromS3 = async (awsStsCredentials: AwsStsCredentials, userKey: string,  fileKey:string, bucketName: string) =>{

    AWS.config.update({
        region: DEALER_CHECKOUT_AWS.AWSS3.region,
        accessKeyId: awsStsCredentials.AccessKeyId,
        secretAccessKey: awsStsCredentials.SecretAccessKey,
        sessionToken: awsStsCredentials.SessionToken
    });
    
    const s3 = new AWS.S3({apiVersion: '2006-03-01'})
    

    const params = {
        Bucket: bucketName,
        Key: userKey + "/" + fileKey
    }

    try{
        await s3.headObject(params).promise()
        try{
            await s3.deleteObject(params).promise();
            return {
                statusCode: 200
            };
        }catch(err){
            return {
                statusCode:500
            }
        }
    }catch(_err){
        return {
            statusCode:500
        }
    }
}

const uploadToS3 = async (awsStsCredentials: AwsStsCredentials, userKey: string, file: File, fileName:string, bucketName: string) =>{

    AWS.config.update({
        region: DEALER_CHECKOUT_AWS.AWSS3.region,
        accessKeyId: awsStsCredentials.AccessKeyId,
        secretAccessKey: awsStsCredentials.SecretAccessKey,
        sessionToken: awsStsCredentials.SessionToken
    });

    const s3 = new AWS.S3({apiVersion: '2006-03-01'})


    const params = {
        Body: file,
        Bucket: bucketName,
        Key: userKey + "/" + fileName
    }

    try{
        const result = await s3.putObject(params).promise();
        return {
            statusCode: result.$response.httpResponse.statusCode 
        };
    }catch(err){
        return {
            statusCode:500
        }
    }
}

export const AwsService = {
    getStsToken:getStsToken,
    uploadToS3: uploadToS3,
    deleteFromS3: deleteFromS3
}